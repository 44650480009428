import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { gfcRevised } from '../../store/layout/gfcRevised';
import { useDispatch, useSelector } from 'react-redux';
import { convertToFormData } from '../ConvertToFormData';
import { postRevisedGFCView } from '../../store/layout/postRevisedGFCView';
import Loader from '../reusable/Loader';
import { handleDownload } from "../reusable/downloadFile";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import otherFile from '../../assets/img/file-icon.png'
import CancelIcon from "@mui/icons-material/Cancel";
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function GfcRevisedLayout({ setSelectedTab }) {
    localStorage.removeItem("isRevisedViewClick");
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const loading = useSelector((state) => state?.gfcRevisedReducer?.loading);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = React.useState();
    const [openPopper, setOpenPopper] = useState(null);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(gfcRevised(data));
            setData(response?.payload?.data);
        } catch (error) {
        }
    }

    useEffect(() => {
        getGfcLayout();
    }, []);

    if (loading) {
        return <Loader />;
    }

    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedGFCViewClick", 1);
            await dispatch(postRevisedGFCView(data));
            setSelectedTab(7)
        } catch (error) {
        }

    }

    const handleClickPopper = (newPlacement, index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopper((prev) => (prev === index ? null : index)); // Toggle popper for this row
        setPlacement(newPlacement);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenPopper(null); 
        setAnchorEl(null);
    };


    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;


    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">GFC Revisions</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {/* <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className='layout-blue-btn'>
                            GFC by KIA
                        </Button>
                        <Button className='layout-lightblue-btn'>
                            Request from Dealer
                        </Button>
                    </Box> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>GFC Send Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/* {data.length >0 ?(
                                    <>
                                      {data?.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell sx={{paddingBlock: "5px !important"}}>{row.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell sx={{paddingBlock: "5px !important"}} align="center" >
                                            <Button className="DownloadBtn" sx={{marginRight: "15px" }}>Download</Button>

                                            <Button className="DownloadBtn"onClick={() =>handleView(row)}>View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))} 
                                </>): (   <StyledTableCell sx={{paddingBlock: "5px !important"}}>No data found</StyledTableCell>)} */}

                                {data?.length > 0 ? (
                                    <>
                                        {data
                                            ?.filter((row) => row.approve_by_admin === true)
                                            .map((row, index) => (
                                                <StyledTableRow key={row.name}>
                                                    <StyledTableCell sx={{ paddingBlock: "5px !important" }}>
                                                    {convertToDateFormatYearWithTwoDigits(row?.created_at)}
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ paddingBlock: "5px !important" }} align="center">
                                                        <Button className="DownloadBtn" sx={{ marginRight: "15px" }}onClick={handleClickPopper('right', index)}>Download</Button>
                                                        <Popper
                                                id={`popper-${index}`}
                                                open={openPopper === index}
                                                anchorEl={anchorEl}
                                                transition
                                                placement={placement}
                                            //  id={id} open={open} anchorEl={anchorEl} transition placement={placement}
                                             >
                                                
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                position: 'relative',
                                                                width: 320,
                                                                minHeight:150,                                
                                                            }}
                                                        >
                                                            <CancelIcon sx={{position: "absolute", right: "5px",top: "5px",cursor:"pointer" }}  onClick={handleClose} />
                                                            {row?.gfc_pdf?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={pdf} className="my-auto" alt="PDF" style={{marginRight:"3px"}}/>
                                                                            <Typography>PDF File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                            {row?.gfc_cad?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={cad} className="my-auto" alt="CAD" style={{marginRight:"3px"}}/>
                                                                            <Typography>CAD File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}

                                                            {row?.gfc_files?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files}>
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={otherFile} className="my-auto" alt="Other File" style={{marginRight:"3px"}}/>
                                                                            <Typography>Other File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>
                                                        <Button className="DownloadBtn" onClick={() => handleView(row)}>View</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </>
                                ) : (
                                    <StyledTableCell sx={{ paddingBlock: "5px !important" }}>No data found or it is not approve by admin</StyledTableCell>
                                )}



                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography, Tooltip, Modal, IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getDesignEstimate } from '../../store/layout/designEstimate';
import { convertToFormData } from '../ConvertToFormData';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import RequestForInvoice from "../reusable/RequestForInvoice";
import { handleDownload } from '../reusable/downloadFile';
import ViewInvoiceModal from '../reusable/ViewInvoiceModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function DesignEstimate() {
  localStorage.removeItem("isRevisedViewClick");
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openInvoiceModal, setInvoiceModalOpen] = useState(false)
  const [data, setData] = useState([]);
  // const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);
  const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);

  const [anchorEl, setAnchorEl] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
   
  const handleInvoiceModalOpen = (invoice) => {
    setInvoice(invoice);
    setInvoiceModalOpen(true);
  };
  const handleInvoiceModalClose = () => {
    setInvoiceModalOpen(false);
    setInvoice(null);
};
  const designEstimate = async () => {
    const formD = {
      'form_id': formId.id
    }
    const data = convertToFormData(formD);
    try {
      const response = await dispatch(getDesignEstimate(data));
      setData(response?.payload?.data);
    } catch (error) {
    }
  }

  useEffect(() => {
    designEstimate();
  }, []);
  
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography variant="h6" className="fs-17  fw-400">Design Estimates</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Date</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Status</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Invoice</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {data?.map((row, index) => (
                    <StyledTableRow key={row.estimate}>
                      <StyledTableCell component="th" scope="row">
                        {row?.estimate_type}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.payment_status == false ? (

                          <Button className="notdoneBtn"> Not Done</Button>
                        ) : (
                          <Button className="doneBtn"> Done</Button>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">    {row.invoice == null ? (
                        <Button className="notdoneBtn" onClick={() => handleOpen()}>Request</Button>
                      ) : (
                        // <Button className="DownloadBtn" onClick={handleClickPopper('right', index)}>View</Button>
                        <Button className="DownloadBtn" onClick={() => handleInvoiceModalOpen(row.invoice)}>View</Button>

                      )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button className="DownloadBtn" onClick={() => handleDownload(new Date() + 'design_estimate', row?.estimate)}>Download</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      <RequestForInvoice open={open} handleClose={handleClose} />
      <ViewInvoiceModal open={openInvoiceModal} handleClose={handleInvoiceModalClose} Invoice={invoice}/>
    </>
  );
}